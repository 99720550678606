<template>
  <div class="data-cont">
    <div class="box shadow">
      <div class="form-group line">
        <div class="label">手机号：</div>
        <div class="cont text">
          {{info.strcontactnum}}
        </div>
      </div>
      <div class="form-group line">
        <div class="label">证件号：</div>
        <div class="cont text">
          {{info.strcardid}}
        </div>
      </div>
    </div>

    <div class="box shadow">
      <div class="form-group line">
        <div class="label">服务名称：</div>
        <div class="cont text">
          {{info.productName}}
        </div>
      </div>
      <div class="form-group line">
        <div class="label">当前IMEI：</div>
        <div class="cont text">
          {{info.imei}}
        </div>
      </div>
      <div class="form-group line">
        <div class="label">更新IMEI：</div>
        <div class="cont">
          <input type="text" v-model.trim="form.strimeinum" placeholder="请输入" :maxlength="15">
        </div>
      </div>
    </div>

    <div class="p-title">
      <p>IMEI查询方式：</p>
      <p>A"设置"菜单-“通用”-“关于手机”查看</p>
      <p>B购买设备的外盒查看</p>
      <p>C手机拨号键盘输入“*#06#”</p>
    </div>

    

    <div class="btns">
      <cube-button class="btn" @click="saveData">立即申请</cube-button>
    </div>


    <div class="modo" v-if="show">
      <div class="modo-in">
        <div class="modo-icon">
          <img src="@/assets/img/success.png" alt="">
        </div>
        <div class="modo-name">您的申请已提交</div>
        <div class="modo-title">
          
        </div>
        <div class="modo-btn" @click="goNext">确定</div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'dataText',
  data () {
    return {
      info: this.$route.query,
      form: {
        id: this.$route.params.id,
        strimeinum: ''
      },
      show: false
    }
  },
  mounted () {
    
  },
  methods: {
    /**
     * IMEI验证
     */
    isImei (str) {
      if(str == ''){
        this.$createToast({
          txt: '请输入IMEI',
          type: 'warn'
        }).show()
        return false;
      }else if(str == 'defaultImeiCode'){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '抱歉，当前IMEI为默认IMEI，无法申请，请操作修改后进行申请。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
          icon: ''
        }).show()
        return false;
      }else if((/^[A-Za-z0-9]+$/.test(str))){ 
        if(str.length > 15 || str.length < 14){
          this.$createDialog({
            type: 'alert',
            title: '',
            content: '抱歉，您输入的IMEI位数不足。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
            icon: ''
          }).show()
          return false;
        }
      }else{
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '抱歉，您输入的IMEI格式不正确。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
          icon: ''
        }).show()
        return false;
      }
      return true
    },
    /**
     * 保存数据
     */
    saveData () {
      const vm = this;
      let postData = {...this.form}

      if(! this.isImei(this.form.strimeinum)){
        return
      }

      if(! this.form.strimeinum){
        this.$createToast({
          txt: '请输入更新IMEI',
          type: 'warn'
        }).show()
        return
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/imei/updateImei", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.show = true;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 下一步
     */
    goNext () {
      this.$router.go(-2)
    }
  },
}
</script>

<style lang="scss" scoped>
.data-cont{
  padding: 30px 0 200px 0;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
}
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 160px;
    }
    .cont{
      flex: 10;
      text-align: right;
      &.text{
        padding: 30px 0;
      }
      input{
        display: block;
        width: 100%;
        text-align: right;
        padding: 30px 0;
        height: 30px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
  }
}
.on-text{
  color: #208170;
  line-height: 90px;
}
.select{
  display: flex;
  text-align: right;
  padding: 30px 0;
  .select-text{
    flex: 10;
  }
  .select-icon{
    padding-left: 18px;
    img{
      display: block;
      height: 30px;
      width: 18px;
    }
  }
}
.check-box{
  display: inline-block;
  box-sizing: border-box;
  height: 64px;
  width: 189px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
  color: #CCCCCC;
  margin-bottom: 30px;
  margin-right: 30px;
  &.on{
    color: #208170;
    border-color: #208170;
  }
}
.form-group{
  .check-box:nth-child(3n){
    margin-right: 0;
  }
}
.textarea-out{
  padding-bottom: 30px;
}
.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  position: absolute;
  bottom: 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}
.p-title{
  font-size: 26px;
  color: #999999;
  line-height: 37px;
  padding: 0 41px;
}
</style>